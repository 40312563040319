import { graphql } from "gatsby";
import React from "react";
import Layout from "../../components/layout";
import { StaticImage } from "gatsby-plugin-image";
import styled from "styled-components";
import { Link, Trans } from "gatsby-plugin-react-i18next";

const ComingSoonKenyaPage = () => {
    return (
        <Layout
            pageTitle="Coming Soon - Moniepoint Kenya"
            hideFooter={true}
            type={"transparent"}
        >
            <HeroContainer>
                <StaticImage
                    alt="..."
                    src="../../images/coming-soon/Nairobi.png"
                    className="background-image"
                />
                <div className="hero-content-wrapper">
                    <div className="hero-content">
                        <h1 className="hero-title">
                            <Trans>comingsoon.hero_title</Trans>
                        </h1>
                        <div className="country-name">
                            <svg
                                width="320"
                                height="68"
                                viewBox="0 0 320 68"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M61.2486 68L31.2431 29.6059L58.9901 0.245766H43.2883L14.3584 30.8965V0.245766H0.915052V68H14.3584V45.5228L21.3489 38.3172L45.1166 68H61.2486ZM117.316 56.8152H79.8894V38.9625H108.497V27.8852H79.8894V11.4306H116.348V0.245766H66.6612V68H117.316V56.8152ZM140.072 0.245766H126.736V68H139.211V20.2494L173.841 68H184.596V0.245766H172.121V45.4153L140.072 0.245766ZM224.543 28.9607L206.152 0.245766H191.203L217.445 41.8662V68H230.565V41.8662L256.807 0.245766H242.288L224.543 28.9607ZM277.232 0.245766L249.808 68H263.358L269.919 51.2228H298.741L305.517 68H319.82L292.181 0.245766H277.232ZM284.007 15.5174H284.438L294.654 40.8983H274.006L284.007 15.5174Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                        <p className="hero-subtitle">
                            <Trans>comingsoon.hero_subtitle</Trans>
                        </p>
                        <Link
                            to="/inside-moniepoint"
                            className="btn btn-primary coming-soon-button"
                        >
                            <Trans>comingsoon.hero_cta</Trans>
                        </Link>

                        <div className="bottom-logo">
                            <svg
                                width="149"
                                height="148"
                                viewBox="0 0 149 148"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <g clipPath="url(#clip0_2481_23943)">
                                    <g clipPath="url(#clip1_2481_23943)">
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M73.7373 147.452H74.2678C108.572 147.452 131.56 140.318 136.334 135.825C141.374 131.333 148.005 107.991 148.005 73.7262C148.005 39.4616 141.374 16.1194 136.334 11.6271C131.56 7.22288 108.572 0.0880839 74.2678 0L73.7373 0C39.5213 0.0880839 16.4454 7.22288 11.6711 11.6271C6.6315 16.1194 0.000488281 39.4616 0.000488281 73.7262C0.000488281 108.079 6.6315 131.333 11.6711 135.825C16.4454 140.23 39.4329 147.364 73.7373 147.452ZM105.299 43.6904L108.128 78.1312L110.074 103.852H99.6407C95.22 103.852 93.3634 101.914 93.0097 97.1573L91.5067 65.359H91.3299C90.0921 70.0275 80.3666 93.3697 80.3666 93.3697H67.5467C67.5467 93.3697 57.8212 69.6751 57.1139 67.2088L56.5834 65.359H56.4066C56.6718 69.4109 54.9035 97.1573 54.9035 97.1573C54.5499 101.914 52.7816 103.852 48.2725 103.852H37.8398L40.2269 73.5508L43.0562 43.6904H60.2084L73.9124 73.8151H74.0893C75.2386 70.2037 88.2354 43.6904 88.2354 43.6904H105.299Z"
                                            fill="white"
                                        />
                                    </g>
                                </g>
                                <defs>
                                    <clipPath id="clip0_2481_23943">
                                        <rect width="148.513" height="147.453" fill="white" />
                                    </clipPath>
                                    <clipPath id="clip1_2481_23943">
                                        <rect width="148.513" height="147.453" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg>
                        </div>
                    </div>
                </div>
                <div className="bottom-box"></div>
            </HeroContainer>
        </Layout>
    );
};

export default ComingSoonKenyaPage;

export const query = graphql`
   query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["common", "index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

const HeroContainer = styled.section`
  background: white;
  background-size: cover;
  background-position: center;
  position: relative;
  height: 100vh;
  background: radial-gradient(
    60.28% 60.28% at 50% 64.94%,
    #0357ee 8.6%,
    #003185 100%
  );

  .background-image {
    position: absolute;
    mix-blend-mode: soft-light;
    bottom: 154px;
    object-fit: cover;
    opacity: 0.9;
    min-height: 221px;
  }

  .bottom-box {
    position: absolute;
    bottom: 0;
    height: 154px;
    width: 100vw;
    background: radial-gradient(
      50% 50% at 50% 14.29%,
      #0357ee 8.6%,
      #003185 100%
    );
    opacity: 0.4;
  }

  .hero-content-wrapper {
    height: 100%;
    max-width: 1440px;
    padding: 94px 99px;
    margin: auto;
    align-items: center;
    display: flex;
    position: relative;
    @media only screen and (max-width: 1024px) {
      padding: 140px 60px;
    }
  }

  .hero-content {
    color: white;

    .hero-title {
      font-family: "Founders Grotesk";
      font-size: 36px;
      font-weight: 500;
      line-height: 39px;
      letter-spacing: 0em;
      max-width: 590px;
      text-align: left;
      margin-bottom: 24px;
    }

    .country-name {
      margin-bottom: 16px;
      svg {
        width: 100%;
        height: auto;
      }
    }
    .hero-subtitle {
      font-size: 17px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0em;
      text-align: left;
      margin-bottom: 22px;
    }

    .coming-soon-button {
      background-color: rgba(255, 255, 255, 0.3);
      backdrop-filter: blur(15.5px);
      border: none;
    }

    .bottom-logo {
      position: absolute;
      right: 99px;
      bottom: 115px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  @media only screen and (max-width: 768px) {
    .hero-content-wrapper {
      padding: 136px 20px 72px;
      align-items: flex-start;
    }

    .hero-content {
      .hero-title {
        font-size: 18px;
        line-height: 19px;
        margin-bottom: 8px;
      }
      .hero-subtitle {
        font-size: 14px;
        line-height: 28px;
        margin-bottom: 16px;
      }

      .country-name {
        margin-bottom: 1px;
        svg {
          height: 60px;
          width: auto;
        }
      }

      .coming-soon-button {
        padding: 14.5px 24px;
      }

      .bottom-logo {
        display: none;
      }
    }
  }
`;
